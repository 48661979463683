import preloader from './preloader'
import headersUI from './headers-ui'
import weatherUI from './weather-ui'
import logoAnimation from './logo-animation'
import copyrightYearUpdate from './copyright-year-update'
import animationsUI from './animations-ui'
import handleNavItemHover from './header'

preloader()

headersUI()

weatherUI()

logoAnimation()

copyrightYearUpdate()

handleNavItemHover()

animationsUI()